<template lang="pug">
  div
    el-alert(
      type="error"
      title="Bu raporu listeleme yetkiniz yok"
      v-if="!can('reports.stock')"
      show-icon
      :closable="false"
      center
    )
    el-card(body-style="padding:0" v-if="can('reports.stock')")
      div(slot="header")
        el-form(inline)
          el-form-item(label="Şube / Depo")
            el-select(
              v-model="filters.warehouses"
              filterable
              clearable
              multiple
              style="width: 100%"
            )
              el-option-group(label="Şubeler")
                el-option(
                  v-for="s in $store.state.branches"
                  :key="s.id"
                  :label="s.name"
                  :value="s.id"
                )
              el-option-group(label="Depolar")
                el-option(
                  v-for="s in $store.state.warehouses"
                  :key="s.id"
                  :label="s.name"
                  :value="s.id"
                )
          el-form-item(label="Satış periodu")
            el-date-picker(
              v-model="filters.period"
              type="daterange"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="$store.state.dateranges"
              range-separator="—"
              start-placeholder="Başlangıç"
              end-placeholder="Bitiş"
              style="width: 300px"
              clearable
            )
          el-form-item
            el-button-group
              el-button(
                icon="el-icon-tickets"
                @click="fetch(false)"
                :loading="fetching"
              ) Getir
              el-button(
                icon="el-icon-download"
                @click="fetch(true)"
                :loading="downloading"
              ) {{ downloading ? 'Bekleyiniz' : 'Excel' }}
      el-table(
        :data="report"
        size="mini"
        :height="$store.state.windowHeight-155"
        v-if="report.length"
        show-summary
        :summary-method="summary"
      )
        el-table-column(prop="id" label="Barkod" sortable width="110px" align="center" show-overflow-tooltip)
        el-table-column(prop="oldid" label="Eski barkod" sortable width="110px" show-overflow-tooltip)
        el-table-column(prop="name" label="Ürün" sortable show-overflow-tooltip)
        el-table-column(prop="supplier" label="Tedarikçi" sortable show-overflow-tooltip)
        el-table-column(label="FİYATLAR" align="center")
          el-table-column(prop="buy" label="Alış fiyatı" sortable align="right")
            template(v-slot="props") {{ props.row.buy }} {{ props.row.buy_currency }}
          el-table-column(prop="sale_price" label="Etiket fiyatı" sortable align="right")
            template(v-slot="props") {{ props.row.sale_price }} {{ props.row.currency }}


        el-table-column(label="MİKTARLAR" align="center")
          el-table-column(prop="available" label="Toplam" sortable align="right")
            template(v-slot="props") {{ props.row.available }} {{ props.row.unit }}
          el-table-column(prop="sold" label="Satılan" sortable align="right")
            template(v-slot="props") {{ props.row.sold }} {{ props.row.unit }}
          el-table-column(prop="left" label="Kalan" sortable align="right")
            template(v-slot="props")
              span(:class="{ 'text-danger text-bold': props.row.left < 0 }")
                | {{ props.row.left }} {{ props.row.unit }}
          el-table-column(prop="sold" label="İade" sortable align="right")
            template(v-slot="props") {{ props.row.returned }} {{ props.row.unit }}
        el-table-column(label="KALAN MAL BEDELLERİ" align="center")
          el-table-column(prop="total_cost" label="Toplam maliyet" sortable align="right" class-name="text-success")
            template(v-slot="props") {{ props.row.total_cost }} {{ props.row.buy_currency }}
          el-table-column(prop="expected_sale" label="Etiket toplamı" sortable align="right" class-name="text-success")
            template(v-slot="props") {{ props.row.expected_sale }} {{ props.row.currency }}
        el-table-column(label="SATIŞ" align="center")
          el-table-column(prop="cost" label="Satış maliyeti" sortable align="right" class-name="text-primary")
            template(v-slot="props") {{ props.row.cost }} {{ props.row.buy_currency }}
          el-table-column(prop="sale" label="Satış" sortable align="right" class-name="text-primary")
            template(v-slot="props") {{ props.row.sale }} {{ props.row.currency }}

          el-table-column(prop="price_avg" label="Ort. satış fiyatı" sortable align="right" class-name="text-primary")
            template(v-slot="props") {{ props.row.price_avg }} {{ props.row.currency }}
          el-table-column(prop="discount" label="İndirim, %" sortable align="right" class-name="text-primary")
      center.p-20
        van-loading(size="48px" color="#f46327" type="spinner" v-if="fetching")
</template>
<script>
  import Product from '@/models/Product'
  import collect from 'collect.js'
  export default {
    name: 'sales-report',
    data(){
      return {
        filters: {
          warehouses: [],
          period: []
        },
        report: [],
        fetching: false,
        downloading: false
      }
    },
    methods: {
      async fetch(excel) {
        if (!excel) {
          this.fetching = true
        } else {
          this.downloading = true
        }
        try{
          let data = await Product.custom('reports/stock')
            .params({ ...this.filters, search: this.search, excel })
            .get()
          if(!excel){
            this.report = data
          }else{
            window.location.href = data[0].url;
            this.downloading = false
          }
          this.fetching = false
        } catch(e) {
          this.fetching = false
          this.$message.error('Sunucu hatası: ' + e.message)
        }
      },
      summary({ columns, data }){
        data = collect(data)
        let totalCost = data.sum('total_cost')
        let expectedSale = data.sum('expected_sale')
        let cost = data.sum('cost')
        let sale = data.sum('sale')
        let targetSale = data.sum(i => i.sale_price*i.sold)
        let discounts = (targetSale/(sale/100)-100).toFixed(2)
        return ['', '', '', '', '', '', '', '', 'TOPLAMLAR', totalCost + ' TRY', expectedSale + ' USD', cost + ' TRY', sale + ' USD', '', discounts + ' %']
      }
    }
  }
</script>
